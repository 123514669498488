import React, { useState, useContext, useEffect } from 'react'
import { Form, Input, Button, Modal, Tag, Alert, Spin } from 'antd'
import FileTags from './FileTags'
//import { StringResources } from '../../share/StringResources'
import { MentionsInput, Mention } from 'react-mentions'
import FormItem from '../override/FormItem'
import PouchDB from 'pouchdb'
// import NodeRSA from 'node-rsa'
import api from '../../lib/api'
// import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import {
  uploadEncryptedData,
  updateLinkItemsForFile,
  getRecords
} from '../../lib/pouchDb'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { useSelector, useDispatch } from 'react-redux'
import { s3Get } from '../../lib/awsSDK'
import ContactSelect from '../common/ContactSelect'
import EventSelect from '../common/EventSelect'
import {
  validateFilename,
  getFileParts,
  getFullFilename
} from '../../share/formHelpers'
import { fetchPendingUnlockFiles } from '../../features/documents/documentsSlice'
import { decryptFile } from '../../lib/crypto'
// import { randomBytes } from 'crypto'
// import { ThemeContext } from 'styled-components'
// import AssetLiabilitySelect from '../assets-liabilities/AssetLiabilitySelect'
import PasswordSelect from '../common/PasswordSelect'
import { onError } from '../../lib/sentry'
import TextInput from '../common/TextInput'
import { useTranslation } from 'react-i18next'
import {
  checkPermission,
  removeHtmlTags,
  sanitizeValue
} from './../../share/helpers'
import { ACCESS_LEVEL } from './../../share/Constants'
import { fetchOtherPendingDocuments } from '../../features/documents/otherDocumentsSlice'
import { Span1 } from '../override/Typography'
import { withRouter } from 'react-router-dom'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import LocationSelect from './../common/LocationSelect'
import { getUserData } from '../../lib/cognito'
import { logDocumentActivity } from '../../share/logs'
import ShowImage from '../common/ShowImage'
import { useMemo } from 'react'
import { uuidRegexExp } from './FileList'

const { TextArea } = Input

function FileDetails(props) {
  const { form, visible, setVisible, docItem, isEdited, setIsEdited, history } =
    props
  const { t } = useTranslation()

  const path = history?.location?.pathname

  const isViewSharedData = useMemo(
    () => uuidRegexExp.test(path.split('/').at(-1)),
    [path]
  )

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form

  const { user, isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { userId, isReadonly, masterKey, permissions, recordIds } =
    useContext(VaultContext)
  // const theme = useContext(ThemeContext)

  const { activeContacts, pendingContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )

  const { activePasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const { activeLocations } = useSelector(state =>
    isReadonly ? state.otherLocations : state.locations
  )
  // const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
  //   state =>
  //     isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  // )

  const { accessLevel } = useSelector(state => state.settings)
  const {
    activeFiles,
    activeFolders,
    pendingUnlockFiles,
    pendingDocuments,
    rejectedDocuments,
    activeDocuments
  } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )

  const { breadcrumb } = useSelector(state => state.documents)

  const [addS3Change] = useMutation(createS3Change)

  const dispatch = useDispatch()
  const [fileData, setFileData] = useState({
    url: '',
    fileName: '',
    description: '',
    tags: [],
    contacts: [],
    assetsLiabilities: [],
    passwords: [],
    location: '',
    descriptionWithMarkup: '',
    name: '',
    extension: ''
  })

  const [isEditMode, setEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  // const [isLocking, setIsLocking] = useState(false)
  // const [isLockable, setIsLockable] = useState(false)
  // const [isRequestingUnlock, setIsRequestingUnlock] = useState(false)
  // const [encryptedBlob, setEncryptedBlob] = useState('')
  const [descriptionValue, setDescriptionValue] = useState('')
  const [tags, setTags] = useState([])
  // const [deputies, setDeputies] = useState([])
  // const [deputiesCanUnlock, setDeputiesCanUnlock] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const [events, setEvents] = useState([])
  const [locationRecord, setLocationRecord] = useState({})
  const { deputies } = useSelector(state => state.deputies)
  // const lstPendingAssetsLiabilities = pendingAssetsLiabilities.filter(pa =>
  //   fileData.assetsLiabilities?.includes(pa._id)
  // )

  const lstPendingContacts = pendingContacts.filter(pc =>
    fileData.contacts.includes(pc._id)
  )

  const setLocation = record => {
    setLocationRecord(record)
  }

  useEffect(() => {
    if (fileData.location && activeLocations) {
      const locationRecord = activeLocations.find(
        item => item._id === fileData.location
      )
      if (locationRecord) {
        setLocationRecord(locationRecord)
      } else {
        setLocationRecord({})
      }
    }
  }, [activeLocations, fileData.location])

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const events = await getRecords(userId, 'events', masterKey)
        setEvents(events)
      } catch (err) {
        onError(err)
      }
    }
    if (visible) {
      fetchEvents()
    }
  }, [userId, masterKey, visible])

  useEffect(() => {
    setEditMode(isEdited || false)
  }, [isEdited])

  useEffect(() => {
    // TODO: refactor to avoid this nesting of user getting session then continue with other works
    const fetchFileData = async () => {
      setIsLoading(true)
      try {
        const db = docItem.status
          ? new PouchDB(`${userId}_pendingDocuments`)
          : new PouchDB(`${userId}_documents`)
        db.crypto(masterKey)
        const doc = await db.get(docItem.id)
        db.removeCrypto()

        const statusRes = await api.getFileStatus(userId, docItem.fileId)
        const fileParts = getFileParts(doc.fileName)

        // TODO: handle error first, can't assume that previous request will always succeed, also consider implementing retries
        if (statusRes.data && statusRes.data.isLocked) {
          // const deputiesCanUnlock = statusRes.data.keys.map(key =>
          //   key.replace(`${userId}/${docItem.fileId}/`, '')
          // )
          // setDeputiesCanUnlock(deputiesCanUnlock)
          setFileData({
            ...doc,
            ...fileParts
          })
          setTags(doc.tags || [])
          setIsLoading(false)
        } else {
          const resBody = await s3Get(
            userId,
            docItem.fileId,
            { sub: doc.sub },
            { responseType: 'blob' }
          )
          decryptFile(resBody, masterKey, uint8Array => {
            const blob = new Blob([uint8Array])
            const file = new File([blob], doc.fileName, {
              type: doc.file[0].type
            })
            const url = URL.createObjectURL(file)
            setFileData({
              ...doc,
              ...fileParts,
              url
            })
            setTags(doc.tags || [])
            setIsLoading(false)
          })

          // setEncryptedBlob(resBody)
        }
      } catch (e) {
        onError(e)
        setErrMsg(t('FAILED_TO_GET_FILE'))
        setIsLoading(false)
      }
    }

    if (
      docItem &&
      docItem.id &&
      docItem.type !== 'folder' &&
      visible &&
      masterKey
    ) {
      fetchFileData()
    }
  }, [
    docItem,
    user,
    userId,
    visible,
    masterKey,
    t,
    accessLevel,
    isProfessionalDeputy
  ])

  // useEffect(() => {
  //   const fetchDeputies = async () => {
  //     try {
  //       const res = await api.getDeputies(userId)
  //       const { deputies } = res.data
  //       if (deputies && deputies.length) {
  //         const acceptedDeputies = deputies.filter(
  //           deputy => deputy.id && deputy.publicKey
  //         )
  //         // setDeputies(acceptedDeputies)
  //         // setIsLockable(!!acceptedDeputies.length)
  //       }
  //     } catch (e) {
  //       onError(e)
  //     }
  //   }

  //   fetchDeputies()
  //   dispatch(fetchPendingUnlockFiles(userId))
  // }, [userId, dispatch])

  useEffect(() => {
    if (visible) dispatch(fetchPendingUnlockFiles(userId))
  }, [userId, dispatch, visible])

  useEffect(() => {
    setDescriptionValue(fileData.descriptionWithMarkup || '')
  }, [fileData])

  // const handleLock = async () => {
  //   Modal.confirm({
  //     title: t('MARKING_FILE_AS_SECRET'),
  //     content: (
  //       <>
  //         <div>{t('MARKING_FILE_AS_SECRET_SUMMARY')}</div>
  //         <div>{t('ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE')}</div>
  //       </>
  //     ),
  //     onOk: async () => {
  //       setIsLocking(true)
  //       try {
  //         const { fileId, sub } = docItem
  //         let keys = []
  //         await Promise.all(
  //           deputies.map(async deputy => {
  //             const key = new NodeRSA()
  //             key.importKey(deputy.publicKey, 'public')

  //             // encryptedBlob was previously encrypted by master key,
  //             // now it's being encrypted the 2nd layer with deputies' public key
  //             const fileKey = randomBytes(20).toString('hex')
  //             keys.push({
  //               deputyId: deputy.id,
  //               key: key.encrypt(fileKey, 'base64')
  //             })

  //             const encryptedFile = await encryptFilePromise(
  //               encryptedBlob,
  //               fileKey
  //             )
  //             await s3Put(userId, `${fileId}/${deputy.id}`, encryptedFile, {
  //               sub
  //             })
  //           })
  //         )

  //         const fileKeys = [
  //           {
  //             fileId,
  //             keys,
  //             isSecretFile: true
  //           }
  //         ]
  //         const fileKeysRes = await api.saveFileKeys(
  //           userId,
  //           JSON.stringify({ fileKeys })
  //         )
  //         if (fileKeysRes.data.message) throw Error(fileKeysRes.data.message)

  //         await api.deleteFile(userId, fileId)
  //         setIsLocking(false)
  //         setVisible(false)
  //         message.success(t('SUCCESSFULLY_MARKED_DOCUMENT_AS_SECRET'))
  //       } catch (e) {
  //         setIsLocking(false)
  //         setErrMsg(t('FAILED_TO_MARK_DOCUMENT_AS_SECRET'))
  //         onError(e)
  //       }
  //     }
  //   })
  // }

  // const requestUnlock = async () => {
  //   getUserData(user, async (err, userData) => {
  //     if (err) {
  //       onError(err)
  //       return
  //     }
  //     setIsRequestingUnlock(true)
  //     try {
  //       const userAttributes = userData.UserAttributes
  //       const userFullname = getUserAttributeValue(
  //         userAttributes,
  //         'custom:full_name'
  //       )
  //       const email = getUserAttributeValue(userAttributes, 'email')

  //       const { fileId, name, sub } = docItem
  //       const fileData = {
  //         fileId,
  //         fileName: name,
  //         userFullname,
  //         email,
  //         deputiesCanUnlock,
  //         sub
  //       }

  //       await api.requestUnlock(userId, JSON.stringify(fileData))
  //       setIsRequestingUnlock(false)
  //       setVisible(false)
  //       message.success(t('REQUEST_TO_UNLOCK_HAS_BEEN_SENT'))
  //     } catch (e) {
  //       onError(e)
  //     }
  //   })
  // }

  const handleContactDeselect = id => {
    const deselectedContact = activeContacts.find(record => record._id === id)
    if (deselectedContact) {
      const markUp = new RegExp(`@\\[(((?!@\\[).)*)\\]\\(${id}\\)`, 'g')
      const newDescriptionValue = descriptionValue.replace(markUp, '$1')
      setDescriptionValue(newDescriptionValue)
    }
  }

  const handleDescriptionChange = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    setFieldsValue({
      description: sanitizeValue(newPlainTextValue).trim()
    })
    setDescriptionValue(newValue)
  }

  const handleContactMention = (id, display) => {
    const selectedContacts = getFieldValue('contacts')
    if (!selectedContacts.includes(id)) {
      setFieldsValue({
        contacts: [...selectedContacts, id]
      })
    }
  }

  const handleSave = item => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      if (item.type === 'folder') {
        return
      }

      removeHtmlTags(values)

      setIsSaving(true)
      try {
        const db =
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? new PouchDB(`${userId}_pendingDocuments`)
            : new PouchDB(`${userId}_documents`)
        db.crypto(masterKey)
        const doc = await db.get(item.id)
        await db.put({
          ...doc,
          //...values,
          tags: removeHtmlTags(tags),
          fileName: getFullFilename(values.fileName, values.extension),
          contacts: values.contacts,
          assetsLiabilities: values.assetsLiabilities,
          description: values.description,
          events: values.events,
          passwords: values.passwords,
          descriptionWithMarkup: sanitizeValue(descriptionValue),
          status:
            (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
              ? 'Draft'
              : undefined,
          reasonReject: undefined,
          location: values.location
        })

        const parentFolders = activeFolders.filter(folder =>
          item.path.includes(folder.path)
        )

        getUserData(
          user,
          async (err, data) => {
            if (err) {
              onError(err)
              return
            }

            await logDocumentActivity(
              userId,
              data.UserAttributes,
              user.username,
              doc._id,
              {
                id: doc._id,
                type: 'file',
                name: getFullFilename(values.fileName, values.extension),
                action: 'edited',
                in: breadcrumb || ''
              }
            )

            if (parentFolders.length) {
              for (const folder of parentFolders) {
                await logDocumentActivity(
                  userId,
                  data.UserAttributes,
                  user.username,
                  folder._id,
                  {
                    id: doc._id,
                    type: 'file',
                    name: getFullFilename(values.fileName, values.extension),
                    action: 'edited',
                    in: breadcrumb || ''
                  }
                )
              }
            }
          },
          { bypassCache: true }
        )

        //get parent folders when edit rejected file
        if (
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ) {
          const parentItems = [
            ...pendingDocuments,
            ...rejectedDocuments,
            ...activeDocuments
          ].filter(d => !d.fileName && item.path.indexOf(d.path) === 0)

          if (parentItems?.length) {
            const updatedParentsItems = parentItems
              .filter(item => item.status === 'Rejected')
              .map(item => {
                return {
                  ...item,
                  status: 'Draft',
                  reasonReject: undefined
                }
              })

            await db.bulkDocs(updatedParentsItems)
          }
        }

        await uploadEncryptedData(
          db,
          userId,
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? 'pendingDocuments'
            : 'documents'
        )

        // update contacts
        const pendingContactIds =
          values.contacts.filter(id =>
            pendingContacts.map(c => c._id).includes(id)
          ) || []
        const activeContactIds =
          values.contacts.filter(id =>
            activeContacts.map(c => c._id).includes(id)
          ) || []

        if (
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ) {
          await updateLinkItemsForFile(
            pendingContactIds,
            fileData.contacts.filter(id =>
              pendingContacts.map(c => c._id).includes(id)
            ) || [],
            'pendingContacts',
            userId,
            masterKey,
            item
          )
        }

        await updateLinkItemsForFile(
          activeContactIds,
          fileData.contacts.filter(id =>
            activeContacts.map(c => c._id).includes(id)
          ) || [],
          'contacts',
          userId,
          masterKey,
          item
        )

        const sharedUsers = deputies?.filter(d =>
          d.records?.map(r => r.id).includes(item.id)
        )

        //update sharing record
        if (sharedUsers) {
          const linkedItems = [
            ...values.contacts,
            ...values.events,
            ...values.passwords,
            ...(values.location ? [values.location] : [])
          ]

          await api.updateSharingRecord(
            userId,
            JSON.stringify({
              recordId: item.id,
              linkedItems
            })
          )
        }

        // // update assets/liablities
        // const pendingAssetLiabilityIds =
        //   values.assetsLiabilities.filter(id =>
        //     pendingAssetsLiabilities.map(c => c._id).includes(id)
        //   ) || []
        // const activeAssetLiabilityIds =
        //   values.assetsLiabilities.filter(id =>
        //     activeAssetsLiabilities.map(pa => pa._id).includes(id)
        //   ) || []

        // if (
        //   (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
        //   accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        // ) {
        //   await updateLinkItemsForFile(
        //     pendingAssetLiabilityIds,
        //     fileData.assetsLiabilities.filter(id =>
        //       pendingAssetsLiabilities.map(pa => pa._id).includes(id)
        //     ) || [],
        //     'pendingAssetsLiabilities',
        //     userId,
        //     masterKey,
        //     item
        //   )
        // }

        // await updateLinkItemsForFile(
        //   activeAssetLiabilityIds,
        //   fileData.assetsLiabilities.filter(id =>
        //     activeAssetsLiabilities.map(pa => pa._id).includes(id)
        //   ) || [],
        //   'assetsLiabilities',
        //   userId,
        //   masterKey,
        //   item
        // )

        // update events
        await updateLinkItemsForFile(
          values.events || [],
          fileData.events || [],
          'events',
          userId,
          masterKey,
          item
        )

        // update passwords
        await updateLinkItemsForFile(
          values.passwords || [],
          fileData.passwords || [],
          'passwords',
          userId,
          masterKey,
          item
        )

        setFileData({
          ...fileData,
          fileName: getFullFilename(values.fileName, values.extension),
          description: values.description,
          tags: removeHtmlTags(tags),
          contacts: values.contacts,
          assetsLiabilities: values.assetsLiabilities,
          events: values.events,
          passwords: values.passwords,
          descriptionWithMarkup: sanitizeValue(descriptionValue),
          name: values.fileName,
          extension: values.extension,
          location: values.location
        })

        if (
          pendingUnlockFiles?.length &&
          pendingUnlockFiles.find(puf => puf.fileId === item.fileId)
        ) {
          const updateData = {
            fileId: item.fileId,
            fileName: getFullFilename(values.fileName, values.extension)
          }

          await api.updateLockFileDetails(userId, JSON.stringify(updateData))
          dispatch(fetchPendingUnlockFiles(userId))
        }
        if (
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ) {
          await api.sendAddRecordNotification(
            JSON.stringify({
              userId,
              recordType: 'document'
            })
          )
          dispatch(fetchOtherPendingDocuments(userId, masterKey))
          setIsEdited(false)
          setVisible(false)
        }
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message:
              'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, locations, passwords',
            userId: userId
          }
        })
        setEditMode(false)
      } catch (e) {
        onError(e)
      }
      setIsSaving(false)
    })
  }

  const handleView = () => {
    var win = window.open()
    win.document.write(
      '<iframe src="' +
        fileData.url +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    )
  }

  const handleEdit = () => {
    setEditMode(true)
  }

  const onCancel = () => {
    form.resetFields()
    setEditMode(false)
  }

  const handleCancel = () => {
    URL.revokeObjectURL(fileData.url)
    setFileData({
      url: '',
      fileName: '',
      description: '',
      tags: [],
      contacts: [],
      events: [],
      assetsLiabilities: [],
      passwords: [],
      descriptionWithMarkup: '',
      name: '',
      extension: ''
    })
    setVisible(false)
    setEditMode(false)
    if (isEdited) {
      setIsEdited(false)
    }
  }

  const readButtons = [
    <Button
      key="download"
      type="primary"
      href={fileData.url}
      download={fileData.fileName}
      style={{ marginRight: 8 }}
    >
      {t('DOWNLOAD')}
    </Button>,
    <Button key="view" onClick={handleView}>
      {t('VIEW')}
    </Button>
  ]
  const writeButtons = [
    <Button key="edit" onClick={handleEdit}>
      {t('EDIT')}
    </Button>
  ]
  // const lockButton = [
  //   !fileData.url ? (
  //     <Button
  //       key="unlock"
  //       onClick={() => requestUnlock()}
  //       loading={isRequestingUnlock}
  //     >
  //       {pendingUnlockFiles?.find(f => f.fileId === docItem.fileId)
  //         ? t('RESEND_UNLOCK_REQUEST')
  //         : t('REQUEST_TO_UNLOCK')}
  //     </Button>
  //   ) : (
  //     <Tooltip
  //       key="lock"
  //       title={
  //         isLockable
  //           ? t('MARK_SECRET_FILE_FIRST_TOOLTIP')
  //           : t('MARK_SECRET_FILE_SECOND_TOOLTIP')
  //       }
  //     >
  //       <Button
  //         onClick={() => handleLock()}
  //         loading={isLocking}
  //         disabled={!isLockable || !encryptedBlob}
  //         style={{ color: theme.red }}
  //       >
  //         {t('MARK_SECRET')}
  //       </Button>
  //     </Tooltip>
  //   )
  // ]

  return (
    <>
      <Modal
        visible={visible}
        title={t('FILE_DETAILS')}
        onCancel={handleCancel}
        width={680}
        footer={
          isLoading
            ? null
            : isEditMode
            ? [
                <Button
                  key="save"
                  type="primary"
                  loading={isSaving}
                  disabled={isViewSharedData}
                  onClick={() => handleSave(docItem)}
                >
                  {t('SAVE')}
                </Button>,
                <Button key="cancel" type="default" onClick={onCancel}>
                  {t('CANCEL')}
                </Button>
              ]
            : [
                ...(!fileData.url ? [] : readButtons),
                ...((isReadonly && !checkPermission(permissions, docItem.id)) ||
                docItem.status
                  ? []
                  : [...writeButtons])
              ]
        }
        maskClosable={false}
      >
        {!isEditMode && (
          <Spin spinning={isLoading}>
            {errMsg && (
              <Alert
                message={errMsg}
                type="error"
                closable
                afterClose={() => setErrMsg('')}
                style={{ marginBottom: 16 }}
              />
            )}
            {/* {fileData.fileName && !fileData.url && (
              <Alert
                message={t('SECRET_FILE')}
                description={t('SECRET_FILE_ALERT_CONTENT')}
                type="info"
                showIcon
                icon={<Icon type="lock" />}
              />
            )} */}
            {pendingUnlockFiles?.find(f => f.fileId === docItem.fileId) && (
              <Alert
                message={t('PENDING_UNLOCK')}
                description={t('PENDING_UNLOCK_SUMMARY')}
                type="info"
                showIcon
              />
            )}

            {/* {!!lstPendingAssetsLiabilities?.length && !isReadonly && (
              <Alert
                type="warning"
                showIcon
                message={
                  <>
                    <Span1>
                      {t('PENDING_ASSET_LIABILITY_WARNING_COUNT', {
                        type: t('FILE'),
                        assetLiabilityCount: lstPendingAssetsLiabilities.length
                      })}
                      :{' '}
                      <b>
                        {lstPendingAssetsLiabilities
                          .map(lpa => lpa.title)
                          .join(', ')}
                      </b>
                    </Span1>

                    <Button type="link" onClick={() => history.push('/')}>
                      {' '}
                      {t('VIEW_DETAILS')}
                    </Button>
                  </>
                }
              />
            )} */}
            {!!lstPendingContacts?.length && !isReadonly && (
              <Alert
                type="warning"
                showIcon
                message={
                  <>
                    <Span1>
                      {t('PENDING_CONTACT_WARNING_COUNT', {
                        type: t('FILE'),
                        contactCount: lstPendingContacts.length
                      })}
                      :{' '}
                      <b>
                        {lstPendingContacts
                          .map(
                            lc =>
                              `${lc.name}${
                                lc.emails?.length
                                  ? ` (${lc.emails[0].value})`
                                  : ''
                              }`
                          )
                          .join(', ')}
                      </b>
                    </Span1>

                    <Button
                      type="link"
                      onClick={() =>
                        history.push('/contacts?activeKey=Pending')
                      }
                    >
                      {' '}
                      {t('VIEW_DETAILS')}
                    </Button>
                  </>
                }
              />
            )}
            <p>
              {t('FILE_NAME')}: {fileData.fileName}
            </p>
            <p>
              {t('DESCRIPTION')}: {fileData.description}
            </p>
            <p>
              {t('TAGS')}:{' '}
              {fileData.tags.map(tag => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </p>
            <p>
              {t('CONTACTS')}:{' '}
              {fileData.contacts &&
                fileData.contacts.map(cId =>
                  (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                    ? [...activeContacts, ...pendingContacts]
                    : activeContacts.filter(
                        ac => !isReadonly || recordIds.includes(ac._id)
                      )
                  ).map(contact =>
                    cId === contact._id ? (
                      <Tag key={contact._id}>{contact.name}</Tag>
                    ) : null
                  )
                )}
            </p>
            {/* <p>
              {t('ASSETS_LIABILITIES')}:{' '}
              {fileData.assetsLiabilities &&
                fileData.assetsLiabilities.map(alId =>
                  (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                    ? [...activeAssetsLiabilities, ...pendingAssetsLiabilities]
                    : activeAssetsLiabilities
                  ).map(al =>
                    alId === al._id ? <Tag key={al._id}>{al.title}</Tag> : null
                  )
                )}
            </p> */}

            <p>
              {t('EVENTS')}:{' '}
              {fileData.events &&
                fileData.events.map(eId =>
                  events
                    .filter(ae => !isReadonly || recordIds.includes(ae._id))
                    .map(e =>
                      eId === e._id ? (
                        <Tag key={e._id}>{e.description}</Tag>
                      ) : null
                    )
                )}
            </p>

            <p>
              {t('PASSWORDS')}:{' '}
              {fileData.passwords &&
                fileData.passwords.map(passwordId =>
                  activePasswords
                    .filter(ap => !isReadonly || recordIds.includes(ap._id))
                    .map(password =>
                      passwordId === password._id ? (
                        <Tag key={password._id}>{password.title}</Tag>
                      ) : null
                    )
                )}
            </p>

            <p>
              {t('PATH')}: {fileData.path}
            </p>

            <p style={{ display: 'flex', flexDirection: 'row' }}>
              {t('Location')}:
              <span style={{ marginLeft: 2 }}>
                {locationRecord.level3
                  ? `${locationRecord.level1}, ${locationRecord.level2}, ${locationRecord.level3}`
                  : locationRecord.level2
                  ? `${locationRecord.level1}, ${locationRecord.level2}`
                  : locationRecord.level1
                  ? locationRecord.level1
                  : ''}
              </span>
            </p>
            {locationRecord && (
              <>
                {locationRecord?.image?.find(image => image.level === 1) && (
                  <>
                    <div style={{ marginBottom: 5 }}>
                      {locationRecord.level1}
                    </div>
                    <ShowImage
                      recordId={locationRecord._id}
                      level={1}
                      width="100%"
                    />
                    <div style={{ marginBottom: 10 }}></div>
                  </>
                )}
                {locationRecord?.image?.find(image => image.level === 2) && (
                  <>
                    <div style={{ marginBottom: 5 }}>
                      {locationRecord.level2}
                    </div>
                    <ShowImage
                      recordId={locationRecord._id}
                      level={2}
                      width="100%"
                    />
                    <div style={{ marginBottom: 10 }}></div>
                  </>
                )}
                {locationRecord?.image?.find(image => image.level === 3) && (
                  <>
                    <div style={{ marginBottom: 5 }}>
                      {locationRecord.level3}
                    </div>
                    <ShowImage
                      recordId={locationRecord._id}
                      level={3}
                      width="100%"
                    />
                  </>
                )}
              </>
            )}
          </Spin>
        )}

        {isEditMode && (
          <Spin spinning={isLoading}>
            <Form className="upload-form">
              <FormItem label={t('FILE_NAME')}>
                {getFieldDecorator('fileName', {
                  initialValue: fileData.name,
                  rules: [
                    {
                      required: true,
                      message: t('INPUT_FILE_NAME_MSG')
                    },
                    {
                      validator: (rule, value, callback) => {
                        const valueToCompare = getFullFilename(
                          value,
                          fileData.extension
                        )

                        validateFilename(
                          rule,
                          valueToCompare,
                          callback,
                          activeFiles.filter(af => af._id !== docItem.id),
                          docItem.path
                        )
                      }
                    }
                  ]
                })(
                  <TextInput
                    addonAfter={fileData.extension && `.${fileData.extension}`}
                    disabled={isViewSharedData}
                  />
                )}
              </FormItem>

              <FormItem>
                {getFieldDecorator('extension', {
                  initialValue: fileData.extension
                })(<Input type="text" hidden disabled={isViewSharedData} />)}
              </FormItem>
              <FormItem label={t('DESCRIPTION')}>
                <MentionsInput
                  value={descriptionValue}
                  onChange={handleDescriptionChange}
                  onBlur={e =>
                    setDescriptionValue(sanitizeValue(e.target.value).trim())
                  }
                  allowSpaceInQuery={true}
                  className="mentions"
                  placeholder={t('INPUT_SHORT_DESCRIPTION_MSG')}
                  maxLength={2000}
                  disabled={isViewSharedData}
                >
                  <Mention
                    className="mentions__mention"
                    data={activeContacts.map(record => ({
                      id: record._id,
                      display: record.name
                    }))}
                    onAdd={handleContactMention}
                  />
                </MentionsInput>
                {getFieldDecorator('description', {
                  initialValue: fileData.description
                })(<TextArea maxLength={2000} style={{ display: 'none' }} />)}
              </FormItem>

              <FormItem label={t('TAGS')}>
                <FileTags
                  tags={tags}
                  setTags={setTags}
                  disabled={isViewSharedData}
                />
              </FormItem>

              <ContactSelect
                label={t('CONTACTS')}
                getFieldDecorator={getFieldDecorator}
                fieldName="contacts"
                initialValue={fileData.contacts || []}
                onDeselect={handleContactDeselect}
                disabled={isViewSharedData}
                mode="multiple"
                onAddComplete={contactId =>
                  setFieldsValue({
                    contacts: [...(getFieldValue('contacts') || []), contactId]
                  })
                }
              />

              <EventSelect
                label={t('EVENT')}
                getFieldDecorator={getFieldDecorator}
                fieldName="events"
                linkedEvents={[]}
                fetchEvents={() => {}}
                setLinkedEvents={() => {}}
                initialValue={fileData.events || []}
                mode="multiple"
                disabled={isViewSharedData}
                onAddComplete={eventId =>
                  setFieldsValue({
                    events: [...(getFieldValue('events') || []), eventId]
                  })
                }
              />
              <PasswordSelect
                label={t('PASSWORDS')}
                placeholder={t('SELECT_PASSWORDS')}
                required={false}
                getFieldDecorator={getFieldDecorator}
                fieldName="passwords"
                initialValue={fileData.passwords || []}
                disabled={isViewSharedData}
                mode="multiple"
                onAddComplete={passwordId =>
                  setFieldsValue({
                    passwords: [
                      ...(getFieldValue('passwords') || []),
                      passwordId
                    ]
                  })
                }
              />
              <LocationSelect
                label={t('Location')}
                placeholder={t('Select location')}
                required={false}
                getFieldDecorator={getFieldDecorator}
                fieldName="location"
                initialValue={fileData.location}
                disabled={isViewSharedData}
                onAddComplete={locationId =>
                  setFieldsValue({
                    location: getFieldValue('location') || locationId
                  })
                }
                setLocation={setLocation}
              />

              {locationRecord && (
                <>
                  {locationRecord?.image?.find(image => image.level === 1) && (
                    <>
                      <div style={{ marginBottom: 5 }}>
                        {locationRecord.level1}
                      </div>
                      <ShowImage
                        recordId={locationRecord._id}
                        level={1}
                        width="100%"
                      />
                      <div style={{ marginBottom: 10 }}></div>
                    </>
                  )}
                  {locationRecord?.image?.find(image => image.level === 2) && (
                    <>
                      <div style={{ marginBottom: 5 }}>
                        {locationRecord.level2}
                      </div>
                      <ShowImage
                        recordId={locationRecord._id}
                        level={2}
                        width="100%"
                      />
                      <div style={{ marginBottom: 10 }}></div>
                    </>
                  )}
                  {locationRecord?.image?.find(image => image.level === 3) && (
                    <>
                      <div style={{ marginBottom: 5 }}>
                        {locationRecord.level3}
                      </div>
                      <ShowImage
                        recordId={locationRecord._id}
                        level={3}
                        width="100%"
                      />
                    </>
                  )}
                </>
              )}
            </Form>
          </Spin>
        )}
      </Modal>
    </>
  )
}
const WrappedFileDetailsForm = Form.create({ name: 'fileDetailsForm' })(
  FileDetails
)

export default withRouter(WrappedFileDetailsForm)
