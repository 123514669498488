import React, { useState, useContext, useEffect, useMemo } from 'react'
import {
  Layout,
  Empty,
  Tag,
  Tooltip,
  Icon,
  Modal,
  message,
  Divider
} from 'antd'
import { H4 } from '../override/Typography'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import SimpleHeader from '../override/SimpleHeader'
import Button from '../override/Button'
import CustomTable from '../override/CustomTable'
import PasswordAddEdit from './PasswordAddEdit'
import VaultContext from '../../contexts/VaultContext'
import FileDetails from '../file/FileDetails'
import { fetchPasswords } from './../../features/passwords/PasswordSlice'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'
import {
  removePasswordFromDb,
  passwordColumns,
  deletePassword
} from './passwordHelpers'
import ExportDropdown from './../assets-liabilities/ExportDropdown'
import { checkPermission } from '../../share/helpers'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import { uuidRegexExp } from '../file/FileList'
import { withRouter } from 'react-router-dom'

const { Content } = Layout

function Passwords(props) {
  const { history } = props
  const [visible, setVisible] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedPassword, setSelectedPassword] = useState('')
  const [showPassword, setShowPassword] = useState('')
  const [docItem, setDocItem] = useState('')
  const [fileDetailsVisible, setFileDetailsVisible] = useState(false)
  const [events, setEvents] = useState([])

  const path = history?.location?.pathname

  const isViewSharedData = useMemo(
    () => uuidRegexExp.test(path.split('/').at(-1)),
    [path]
  )

  const [addS3Change] = useMutation(createS3Change)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { userId, masterKey, isReadonly, recordIds, permissions } =
    useContext(VaultContext)
  // const { activeAssetsLiabilities } = useSelector(
  //   state => state.assetsLiabilities
  // )

  const { activeDocuments } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )

  const { activePasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const events = await getRecords(userId, 'events', masterKey)
        setEvents(events)
      } catch (err) {
        onError(err)
      }
    }

    fetchEvents()
  }, [userId, masterKey])

  const handleDeletePassword = record => {
    Modal.confirm({
      title: t('CONFIRM_DELETE'),
      content: <>{t('CONFIRM_DELETE_ITEM_MSG')}</>,
      async onOk() {
        try {
          await deletePassword(userId, record, masterKey)

          //update AssetsLiabilities
          if (record.assetsLiabilities?.length) {
            await removePasswordFromDb(
              userId,
              masterKey,
              record,
              'assetsLiabilities'
            )
          }

          //update Events
          if (record.events?.length) {
            await removePasswordFromDb(userId, masterKey, record, 'events')
          }

          //update Documents
          if (record.documents?.length) {
            await removePasswordFromDb(userId, masterKey, record, 'documents')
          }

          message.success(t('SUCCESSFULLY_DELETED_THE_PASSWORD'))
          dispatch(fetchPasswords(userId, masterKey))

          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message: 'passwords, documents, events',
              userId: userId
            }
          })
        } catch (error) {
          message.error(t('FAILED_TO_DELETE_THE_PASSWORD'))
        }
      },
      onCancel() {}
    })
  }

  const columns = [
    {
      key: 'title',
      width: 200,
      dataIndex: 'title',
      title: <span className="dragHandler">{t('TITLE')}</span>,
      render: (text, record) => <span>{record.title}</span>,
      sorter: (a, b) => a.title.localeCompare(b.title),
      defaultSortOrder: 'ascend'
    },
    {
      key: 'url',
      width: 200,
      dataIndex: 'url',
      title: <span className="dragHandler">{t('URL')}</span>,
      render: (text, record) => {
        const url = record.url
        if (url?.length > 100) {
          const showURL = url.slice(0, 97).concat('...')
          return (
            <Tooltip placement="bottom" title={url}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {showURL}
              </a>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip placement="bottom" title={url}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </Tooltip>
          )
        }
      },
      sorter: (a, b) => a.url.localeCompare(b.url)
    },
    {
      key: 'username',
      width: 250,
      dataIndex: 'username',
      title: <span className="dragHandler">{t('USERNAME')}</span>,
      render: (text, record) => <span>{record.username}</span>,
      sorter: (a, b) => a.username.localeCompare(b.username)
    },
    {
      key: 'password',
      width: 250,
      dataIndex: 'password',
      title: <span className="dragHandler">{t('PASSWORD')}</span>,
      render: (text, record) => {
        const passwordLength = record.password.length
        let hidenPassword = ''
        for (let i = 0; i < passwordLength; i++) {
          hidenPassword += '*'
        }
        if (showPassword === record._id) {
          return (
            <>
              <span style={{ marginRight: '5px' }}>{record.password}</span>
              <Icon onClick={() => setShowPassword('')} type="eye-invisible" />
            </>
          )
        } else {
          return (
            <>
              <span style={{ marginRight: '5px' }}>{hidenPassword}</span>
              <Icon onClick={() => setShowPassword(record._id)} type="eye" />
            </>
          )
        }
      }
    },
    {
      key: 'note',
      width: 200,
      dataIndex: 'note',
      title: <span className="dragHandler">{t('NOTE')}</span>,
      render: (text, record) => <span>{record.note}</span>
    },
    // {
    //   key: 'assetsLiabilities',
    //   width: 200,
    //   dataIndex: 'assetsLiabilities',
    //   title: <span className="dragHandler">{t('ASSETS_LIABILITIES')}</span>,
    //   render: (text, record) => (
    //     <span className="item-name">
    //       {record.assetsLiabilities &&
    //         record.assetsLiabilities.map(alId =>
    //           activeAssetsLiabilities.map(al =>
    //             alId === al._id ? <Tag key={al._id}>{al.title}</Tag> : null
    //           )
    //         )}
    //     </span>
    //   )
    // },
    {
      key: 'documents',
      width: 200,
      dataIndex: 'documents',
      title: <span className="dragHandler">{t('DOCUMENTS')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.documents &&
            record.documents.map(docId =>
              activeDocuments.map(d => {
                if (docId === d._id) {
                  const item = {
                    id: d._id,
                    name: d.fileName,
                    ...d
                  }
                  return (
                    <Tag onClick={() => showFileDetails(item)} key={d._id}>
                      {d.fileName}
                    </Tag>
                  )
                } else {
                  return null
                }
              })
            )}
        </span>
      )
    },
    {
      key: 'events',
      width: 200,
      dataIndex: 'events',
      title: <span className="dragHandler">{t('EVENTS')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.events &&
            record.events.map(eventId =>
              events.map(event =>
                eventId === event._id ? (
                  <Tag key={event._id}>{event.description}</Tag>
                ) : null
              )
            )}
        </span>
      )
    },
    {
      key: 'actions',
      width: 200,
      dataIndex: 'actions',
      render: (text, record) =>
        (!isReadonly || checkPermission(permissions, record._id)) && (
          <div
            style={{ textAlign: 'end', minWidth: 40 }}
            onClick={e => e.stopPropagation()}
          >
            <Tooltip
              title={t('EDIT_PASSWORD')}
              arrowPointAtCenter
              placement="topRight"
            >
              <Icon
                theme="twoTone"
                type="edit"
                onClick={() => {
                  setIsEditMode(true)
                  setVisible(true)
                  setSelectedPassword(record)
                }}
              />
            </Tooltip>{' '}
            <Tooltip
              title={t('REMOVE_PASSWORD')}
              arrowPointAtCenter
              placement="topRight"
            >
              <Icon
                onClick={() => handleDeletePassword(record)}
                theme="twoTone"
                twoToneColor="#eb4444"
                type="delete"
              />
            </Tooltip>
          </div>
        )
    }
  ]

  const showFileDetails = item => {
    setDocItem(item)
    setFileDetailsVisible(true)
  }

  return (
    <>
      <Layout
        style={{
          height: '100%',
          padding: '0 20px 20px',
          backgroundColor: '#fff'
        }}
      >
        <SimpleHeader
          title={<H4 display="inline-block">{t('PASSWORDS')}</H4>}
          extra={
            <>
              <ExportDropdown
                style={{ height: 33 }}
                fileName="Passwords"
                columns={passwordColumns(activeDocuments, events)}
                source={activePasswords}
                subTitle="List of Passwords"
              />
              {!isReadonly && (
                <>
                  <Divider type="vertical" />
                  <Button icon="plus" onClick={() => setVisible(true)}>
                    {t('CREATE_NEW')}
                  </Button>
                </>
              )}
            </>
          }
        />
        <Content>
          {activePasswords?.length ? (
            <CustomTable
              rowKey="_id"
              columns={columns}
              dataSource={
                isViewSharedData
                  ? activePasswords.filter(ap => recordIds.includes(ap._id))
                  : activePasswords
              }
              scroll={{ x: true }}
            />
          ) : (
            <Empty />
          )}
        </Content>
      </Layout>

      <PasswordAddEdit
        selectedPassword={selectedPassword}
        visible={visible}
        setVisible={setVisible}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
      <FileDetails
        visible={fileDetailsVisible}
        setVisible={setFileDetailsVisible}
        docItem={docItem}
      />
    </>
  )
}

export default withRouter(Passwords)
