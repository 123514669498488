import React, { useState, useContext, useEffect } from 'react'
import {
  List,
  Icon,
  Button,
  Menu,
  Modal,
  Dropdown,
  Tooltip,
  Table,
  message,
  Divider
} from 'antd'
import FileDetails from './FileDetails'
import {
  toggleStar,
  unlinkDocument,
  deleteDocument,
  deleteFolder,
  renameFolder,
  moveItem,
  bulkDeleteDocuments,
  moveItems,
  copyItems,
  unlinkDocuments,
  newDocumentsToCopy,
  rejectDocument,
  approveDocument,
  permanentlyDeleteDocuments
} from '../../lib/pouchDb'
import { filesGridLayout, filesGridLayoutInModal } from '../../share/Layout'
import CreateFolderModal from '../file/CreateFolderModal'
import { checkPermission, queryLinkedRecords } from '../../share/helpers'
import { useSelector, useDispatch } from 'react-redux'
import FileDirectory from '../file/FileDirectory'
import { ThemeContext } from 'styled-components'
import { s3Get } from '../../lib/awsSDK'
import { decryptFile } from '../../lib/crypto'
import { Span } from '../override/Typography'
import { uniqBy, uniq, sum } from 'lodash'
import { getLegacyInfo } from '../../features/settings/settingsSlice'
import VaultContext from '../../contexts/VaultContext'
import { onError } from '../../lib/sentry'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation, Trans } from 'react-i18next'
import { removeHtmlTags } from './../../share/helpers'
import { ACCESS_LEVEL } from './../../share/Constants'
import AuthContext from '../../contexts/AuthContext'
import { fetchPendingDocuments } from './../../features/documents/documentsSlice'
import RejectModal from '../modals/RejectModal'
import '../common/Common.scss'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import api from '../../lib/api'
import { folderWithIcon } from './FolderIcons'
import ShareDataModal from '../modals/SharingDataModal'
// import { fetchDeputies } from './../../features/deputies/deputiesSlice'
import moment from 'moment'
// import { getUserData } from '../../lib/cognito'
// import { logDocumentActivity } from '../../share/logs'
import ShowLocationImageModal from '../common/ShowLocationImageModal'
import { withRouter } from 'react-router-dom'
import { useMemo } from 'react'

export const uuidRegexExp =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

function StarIcon({ item, handleStar, isReadonly, t }) {
  const theme = useContext(ThemeContext)

  return (
    <Tooltip
      title={
        isReadonly
          ? null
          : item.starred
          ? t('REMOVE_FROM_FAVOURITES')
          : t('ADD_TO_FAVOURITES')
      }
    >
      <Icon
        type="star"
        theme={item.starred ? 'filled' : 'outlined'}
        style={{ color: theme.secondaryOrange }}
        onClick={e => {
          e.stopPropagation()
          handleStar(item)
        }}
      />
    </Tooltip>
  )
}

// TODO: Crop file name when it's too long
function FileList(props) {
  const {
    content,
    isReadonly,
    linkedAssetLiabilityId,
    filteredDocIds,
    setDocToLink,
    userId,
    breadcrumb,
    setBreadcrumb,
    viewMode,
    showStarredDocsOnly,
    setShowStarredDocsOnly,
    setEnableCreateFile,
    setSelectedTextFile,
    isModal,
    isSelectedMutilpleFiles,
    setSelectedFilesFolders,
    selectedFilesFolders,
    viewDetail,
    selectedDoc,
    setSelectedDoc,
    setStartDate,
    setEndDate,
    setIsShowSider,
    history
  } = props
  const theme = useContext(ThemeContext)
  const { user, isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    activeDocuments,
    activeFolders,
    activeFiles,
    pendingFiles,
    usedStorage,
    pendingDocuments
  } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )

  const { allowedStorage } = useSelector(state => state.customer)
  const { accessLevel } = useSelector(state => state.settings)
  const { backupSize } = useSelector(state => state.user).user

  const { activeEvents } = useSelector(state =>
    isReadonly ? state.otherEvents : state.events
  )
  const { activeLocations } = useSelector(state =>
    isReadonly ? state.otherLocations : state.locations
  )
  const { activePasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )

  const { activeContacts, pendingContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state =>
      isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { masterKey, fullName, recordIds, permissions } =
    useContext(VaultContext)

  const [visible, setVisible] = useState(false)
  const [editFormVisible, setEditFormVisible] = useState(false)
  const [docItem, setDocItem] = useState({})
  // const [selectedDoc, setSelectedDoc] = useState()
  const [directoryModalVisible, setDirectoryModalVisible] = useState(false)
  const [destinationFolder, setDestinationFolder] = useState('')
  const [action, setAction] = useState('')
  const [isMovingOrCopying, setIsMovingOrCopying] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [rejectModalVisible, setRejectModalVisible] = useState(false)
  const [rejectRecord, setRejectRecord] = useState({})
  const [rejecting, setRejecting] = useState(false)
  const [shareDataModalVisible, setShareDataModalVisible] = useState(false)
  const [locationLevel, setLocationLevel] = useState('')
  const [locationRecordId, setLocationRecordId] = useState('')
  const [locationImageModalVisible, setLocationImageModalVisible] =
    useState(false)
  const [firstSelectedIndex, setFirstSelectedIndex] = useState()
  const [currentData, setCurrentData] = useState([])
  const [isEdited, setIsEdited] = useState(false)
  let rejectFormRef

  const path = history?.location?.pathname
  const isViewSharedData = useMemo(
    () => uuidRegexExp.test(path.split('/').at(-1)),
    [path]
  )

  const [addS3Change] = useMutation(createS3Change)

  useEffect(() => {
    setSelectedRows([])
  }, [breadcrumb, userId])

  const handleDoubleClick = item => {
    if (item.type === 'folder') {
      setBreadcrumb(item.path)
      showStarredDocsOnly && setShowStarredDocsOnly(false)
    } else if (item.type === 'file-text') {
      setDocItem(item)
      setVisible(true)
    }
  }

  const handleClick = (item, event, index) => {
    let lastSelectedIndex
    if (!event.shiftKey) {
      setFirstSelectedIndex(index)
    }

    viewDetail(item.id)
    setSelectedDoc(item)
    setStartDate(moment())
    setEndDate(null)
    setIsShowSider(true)
    if (isModal && !isSelectedMutilpleFiles) {
      setSelectedDoc(item.id)
      if (!setDocToLink) return
      if (item.type === 'file-text' && !isLinked(item.id)) {
        setDocToLink(item.id)
      } else {
        setDocToLink(null)
      }
    } else {
      const selectedItems = isSelectedMutilpleFiles
        ? selectedFilesFolders
        : selectedRows

      const selected = selectedItems.find(sr => sr.id === item.id)
        ? selectedItems.filter(sr => sr.id !== item.id)
        : [...selectedItems, item]
      if (event.ctrlKey) {
        setSelectedRows(
          selected.filter(sr =>
            sr.type === 'folder'
              ? !sr.status &&
                sr.pendingFilesCount === 0 &&
                sr.pendingFoldersCount === 0
              : !sr.status
          )
        )
      } else if (event.shiftKey) {
        if (firstSelectedIndex === index) {
          setFirstSelectedIndex(index)
        } else {
          lastSelectedIndex = index
          const data = currentData?.length
            ? currentData
            : (recordIds?.length
                ? content.filter(c => recordIds.includes(c.id))
                : content
              ).sort((a, b) =>
                a.type === b.type
                  ? getFileExtension(a.name) === getFileExtension(b.name)
                    ? a.name === b.name
                      ? ''
                      : a.name.localeCompare(b.name)
                    : getFileExtension(a.name).localeCompare(
                        getFileExtension(b.name)
                      )
                  : b.type.localeCompare(a.type)
              )
          let startIndex, endIndex
          if (firstSelectedIndex < lastSelectedIndex) {
            startIndex = firstSelectedIndex
            endIndex = lastSelectedIndex
          } else {
            startIndex = lastSelectedIndex
            endIndex = firstSelectedIndex
          }

          let selectedItems = []
          for (let i = startIndex; i <= endIndex; i++) {
            selectedItems = [...selectedItems, data[i]]
          }

          setSelectedRows(selectedItems)
        }
      } else {
        setSelectedRows([item])
      }
      if (isSelectedMutilpleFiles) setSelectedFilesFolders(selected)
    }
  }

  const handleStar = async item => {
    if (isReadonly) return

    try {
      await toggleStar(userId, item.id, masterKey)
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'documents, pendingDocuments',
          userId: userId
        }
      })
    } catch (e) {
      onError(e)
    }
  }

  const isLinked = itemId => {
    return filteredDocIds && filteredDocIds.includes(itemId)
  }

  // const handleRemove = async item => {
  //   try {
  //     const linkedItems = [...activeContacts, ...activeDocuments]?.filter(
  //       i => i.documents?.includes(item.id) || i.contacts?.includes(item.id)
  //     )

  //     const data = {
  //       userId: user.username,
  //       recordId: item.id
  //     }

  //     if (linkedItems?.length) {
  //       Modal.confirm({
  //         title: t('Revoke linked item'),
  //         content: `${linkedItems.length} item(s) will be unshared. Are you want to revoke?`,
  //         onCancel: () => {},
  //         onOk: async () => {
  //           await Promise.all(
  //             linkedItems.map(async it => {
  //               await api.revokeSharing(
  //                 userId,
  //                 JSON.stringify({
  //                   userId: user.username,
  //                   recordId: it._id
  //                 })
  //               )

  //               if (Object.keys(it).includes('path')) {
  //                 getUserData(
  //                   user,
  //                   async (err, data) => {
  //                     if (err) {
  //                       onError(err)
  //                       return
  //                     }
  //                     await logDocumentActivity(
  //                       userId,
  //                       data.UserAttributes,
  //                       user.username,
  //                       it.id,
  //                       {
  //                         id: it.id,
  //                         type: it.type === 'folder' ? 'folder' : 'file',
  //                         name: it.name,
  //                         action: 'removed',
  //                         from: it.path
  //                       }
  //                     )
  //                   },
  //                   { bypassCache: true }
  //                 )
  //               }
  //             })
  //           )
  //           await api.revokeSharing(userId, JSON.stringify(data))
  //           dispatch(fetchDeputies(userId))
  //           message.success('Successfully remove item')
  //         }
  //       })
  //     } else {
  //       if (Object.keys(item).includes('path')) {
  //         getUserData(
  //           user,
  //           async (err, data) => {
  //             if (err) {
  //               onError(err)
  //               return
  //             }
  //             await logDocumentActivity(
  //               userId,
  //               data.UserAttributes,
  //               user.username,
  //               item.id,
  //               {
  //                 id: item.id,
  //                 type: item.type === 'folder' ? 'folder' : 'file',
  //                 name: item.name,
  //                 action: 'removed',
  //                 from: item.path
  //               }
  //             )
  //           },
  //           { bypassCache: true }
  //         )
  //       }
  //       await api.revokeSharing(userId, JSON.stringify(data))
  //       dispatch(fetchDeputies(userId))
  //       message.success('Successfully remove item')
  //     }
  //   } catch (error) {
  //     message.error('Failed to remove item!')
  //   }
  // }

  // Move to Trash
  const handleDeleteItem = item => {
    let linkedContacts = [],
      linkedPendingContacts = [],
      linkedEvents = [],
      linkedAssetsLiabilities = [],
      linkedPendingAssetsLiabilities = [],
      linkedItemsMessages = [],
      linkedPasswords = []

    if (item.type !== 'folder') {
      linkedAssetsLiabilities = queryLinkedRecords(
        activeAssetsLiabilities,
        'documents',
        [item.id],
        'every'
      )

      linkedPendingAssetsLiabilities = queryLinkedRecords(
        pendingAssetsLiabilities,
        'documents',
        [item.id],
        'every'
      )

      linkedContacts = queryLinkedRecords(
        activeContacts,
        'documents',
        [item.id],
        'every'
      )

      linkedPendingContacts = queryLinkedRecords(
        pendingContacts,
        'documents',
        [item.id],
        'every'
      )

      linkedPasswords = queryLinkedRecords(
        activePasswords,
        'documents',
        [item.id],
        'every'
      )

      linkedEvents = queryLinkedRecords(
        activeEvents,
        'documents',
        [item.id],
        'every'
      )

      const totalLinkedALs =
        linkedAssetsLiabilities.length + linkedPendingAssetsLiabilities.length

      if (
        linkedAssetsLiabilities?.length ||
        linkedPendingAssetsLiabilities?.length
      ) {
        linkedItemsMessages.push(
          `${totalLinkedALs} ${t('ASSET_LIABILITY_RECORDS')}`
        )
      }

      const totalLinkedContacts =
        linkedContacts.length + linkedPendingContacts.length

      if (linkedContacts?.length || linkedPendingContacts?.length) {
        linkedItemsMessages.push(
          `${totalLinkedContacts} ${t('CONTACT_RECORDS')}`
        )
      }

      linkedPasswords?.length &&
        linkedItemsMessages.push(
          `${linkedPasswords.length} ${t('PASSWORD_RECORDS')}`
        )

      linkedEvents?.length &&
        linkedItemsMessages.push(`${linkedEvents.length} ${t('EVENT_RECORDS')}`)
    }

    Modal.confirm({
      title: t('CONFIRM_DELETE'),
      content: (
        <>
          {!!linkedItemsMessages.length && (
            <p>
              {`${linkedItemsMessages.join(' & ')} ${t(
                'WILL_BE_UNLINKED_FROM_THIS_ITEM'
              )}`}
            </p>
          )}
          <div>{t('CONFIRM_DELETE_ITEM_MSG')}</div>
        </>
      ),
      async onOk() {
        try {
          if (item.type === 'folder') {
            await deleteFolder(
              user,
              activeDocuments,
              item,
              userId,
              masterKey,
              activeFolders,
              breadcrumb
            )
          } else {
            const legacyInfo = await getLegacyInfo(userId, masterKey)

            if (legacyInfo?.instructionFileId === item.fileId) {
              message.error(t('FILE_USED_IN_LEGACY_MANAGEMENT'))
              return
            }

            await deleteDocument(
              user,
              userId,
              item.id,
              masterKey,
              activeFolders,
              breadcrumb
            )
            linkedContacts?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'contacts',
                linkedContacts,
                masterKey
              ))

            linkedPendingContacts?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'pendingContacts',
                linkedPendingContacts,
                masterKey
              ))

            linkedEvents?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'events',
                linkedEvents,
                masterKey
              ))

            linkedPasswords?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'passwords',
                linkedPasswords,
                masterKey
              ))

            linkedAssetsLiabilities?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'assetsLiabilities',
                linkedAssetsLiabilities,
                masterKey
              ))

            linkedPendingAssetsLiabilities?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'pendingAssetsLiabilities',
                linkedPendingAssetsLiabilities,
                masterKey
              ))
          }
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message:
                'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, locations, passwords',
              userId: userId
            }
          })
          message.success(
            `${t('SUCCESSFULLY_DELETED')} ${
              item.type === 'folder'
                ? t('FOLDER').toLowerCase()
                : t('FILE').toLowerCase()
            }`
          )
        } catch (err) {
          message.error(
            `${t('FAILED_TO_DELETE')} ${
              item.type === 'folder'
                ? t('FOLDER').toLowerCase()
                : t('FILE').toLowerCase()
            }`
          )
          onError(err)
        }
      },
      onCancel() {}
    })
  }

  // delete multiple records
  const handleDeleteItems = async () => {
    let allLinkedContacts = [],
      allLinkedPendingContacts = [],
      allLinkedAssetsLiabilities = [],
      allLinkedPendingAssetsLiabilities = [],
      allLinkedEvents = [],
      allLinkedPasswords = [],
      linkedItemsMessages = []

    if (selectedRows.find(sr => sr.type === 'folder' && sr.filesCount > 0)) {
      message.error(
        `${t('FAILED_TO_DELETE')}: ${t(
          'CANNOT_DELETE_FOLDERS_WITH_FILES_INSIDE'
        )}`
      )
      return
    }

    const fileItems = selectedRows.filter(sr => sr.type === 'file-text')

    const legacyInfo = await getLegacyInfo(userId, masterKey)

    if (legacyInfo) {
      const instructionFile = fileItems.find(
        item => item.fileId === legacyInfo.instructionFileId
      )
      if (instructionFile) {
        message.error(
          <Trans
            i18nKey="CANNOT_BE_DELETED_BECAUSE_IT_USED_IN_LEGACY_MANAGEMENT"
            values={{ name: instructionFile.name }}
          ></Trans>
        )
        return
      }
    }

    fileItems.forEach(item => {
      const linkedAssetsLiabilities = queryLinkedRecords(
        activeAssetsLiabilities,
        'documents',
        [item.id],
        'every'
      )

      const linkedPendingAssetsLiabilities = queryLinkedRecords(
        pendingAssetsLiabilities,
        'documents',
        [item.id],
        'every'
      )

      const linkedContacts = queryLinkedRecords(
        activeContacts,
        'documents',
        [item.id],
        'every'
      )

      const linkedPendingContacts = queryLinkedRecords(
        pendingContacts,
        'documents',
        [item.id],
        'every'
      )

      const linkedPasswords = queryLinkedRecords(
        activePasswords,
        'documents',
        [item.id],
        'every'
      )

      const linkedEvents = queryLinkedRecords(
        activeEvents,
        'documents',
        [item.id],
        'every'
      )

      linkedAssetsLiabilities?.length &&
        allLinkedAssetsLiabilities.push(linkedAssetsLiabilities)
      linkedPendingAssetsLiabilities?.length &&
        allLinkedPendingAssetsLiabilities.push(linkedPendingAssetsLiabilities)
      linkedContacts?.length && allLinkedContacts.push(linkedContacts)
      linkedPendingContacts?.length &&
        allLinkedPendingContacts.push(linkedPendingContacts)
      linkedEvents?.length && allLinkedEvents.push(linkedEvents)
      linkedPasswords?.length && allLinkedPasswords.push(linkedPasswords)
    })

    const linkedAssetsLiabilitiesArr = uniqBy(
      allLinkedAssetsLiabilities?.flat(),
      ab => ab._id
    )
    const linkedPendingAssetsLiabilitiesArr = uniqBy(
      allLinkedPendingAssetsLiabilities?.flat(),
      ab => ab._id
    )
    const linkedContactsArr = uniqBy(allLinkedContacts?.flat(), c => c._id)

    const linkedPendingContactsArr = uniqBy(
      allLinkedPendingContacts?.flat(),
      c => c._id
    )

    const linkedEventsArr = uniqBy(allLinkedEvents?.flat(), e => e._id)
    const linkedPasswordsArr = uniqBy(allLinkedPasswords?.flat(), e => e._id)

    const totalLinkedALs =
      linkedAssetsLiabilitiesArr.length +
      linkedPendingAssetsLiabilitiesArr.length

    if (
      linkedAssetsLiabilitiesArr?.length ||
      linkedPendingAssetsLiabilitiesArr?.length
    ) {
      linkedItemsMessages.push(
        `${totalLinkedALs} ${t('ASSET_LIABILITY_RECORDS')}`
      )
    }

    const totalLinkedContacts =
      linkedContactsArr.length + linkedPendingContactsArr.length

    if (linkedContactsArr?.length || linkedPendingContactsArr?.length) {
      linkedItemsMessages.push(`${totalLinkedContacts} ${t('CONTACT_RECORDS')}`)
    }

    linkedEventsArr?.length &&
      linkedItemsMessages.push(
        `${linkedEventsArr.length} ${t('EVENT_RECORDS')}`
      )

    linkedPasswordsArr?.length &&
      linkedItemsMessages.push(
        `${linkedPasswordsArr.length} ${t('PASSWORD_RECORDS')}`
      )

    Modal.confirm({
      title: t('CONFIRM_DELETE'),
      content: (
        <>
          {!!linkedItemsMessages.length && (
            <p>
              {`${linkedItemsMessages.join(' & ')} ${t(
                'WILL_BE_UNLINKED_FROM_THIS_ITEM'
              )}`}
            </p>
          )}
          <div>{t('CONFIRM_DELETE_ITEMS_MSG')}</div>
        </>
      ),
      async onOk() {
        try {
          await bulkDeleteDocuments(
            user,
            activeFolders,
            activeFiles,
            selectedRows,
            userId,
            masterKey,
            breadcrumb
          )

          linkedAssetsLiabilitiesArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'assetsLiabilities',
              linkedAssetsLiabilitiesArr,
              masterKey
            ))

          linkedPendingAssetsLiabilitiesArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'pendingAssetsLiabilities',
              linkedPendingAssetsLiabilitiesArr,
              masterKey
            ))

          linkedContactsArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'contacts',
              linkedContactsArr,
              masterKey
            ))

          linkedPendingContactsArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'pendingContacts',
              linkedPendingContactsArr,
              masterKey
            ))

          linkedEventsArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'events',
              linkedEventsArr,
              masterKey
            ))

          linkedPasswordsArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'passwords',
              linkedPasswordsArr,
              masterKey
            ))

          setSelectedRows([])
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message:
                'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, location, passwords',
              userId: userId
            }
          })
          message.success(
            <Trans
              i18nKey="SUCCESSFULLY_DELETED_ITEMS"
              values={{ count: selectedRows.length }}
            ></Trans>
          )
        } catch (err) {
          message.error(t('FAILED_TO_DELETE_ITEMS'))
          onError(err)
        }
      },
      onCancel() {}
    })
  }

  let editFolderForm
  const editFolderFormRef = fr => {
    editFolderForm = fr
  }

  const handleRenameFolder = item => {
    editFolderForm.props.form.validateFields(async (err, values) => {
      if (err) return
      removeHtmlTags(values)
      if (
        values.folderName.trim() === item.name &&
        values.folderIcon === item.folderIcon &&
        values.location === item.location
      ) {
        setEditFormVisible(false)
        return
      }

      try {
        await renameFolder(
          user,
          activeFolders,
          activeDocuments,
          item,
          userId,
          values.folderName,
          values.folderIcon,
          values.folderType,
          values.location,
          masterKey
        )
        message.success(t('SUCCESSFULLY_RENAMED_FOLDER'))
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'documents',
            userId: userId
          }
        })
        setEditFormVisible(false)
      } catch (err) {
        message.error(t('FAILED_TO_RENAME_FOLDER'))
        onError(err)
      } finally {
        setDocItem({})
      }
    })
  }

  const handleRejectDocument = () => {
    setRejecting(true)
    rejectFormRef.props.form.validateFields(async (err, values) => {
      if (err) return

      const linkedContacts = queryLinkedRecords(
        activeContacts,
        'documents',
        [rejectRecord.id],
        'every'
      )

      const linkedPendingContacts = queryLinkedRecords(
        pendingContacts,
        'documents',
        [rejectRecord.id],
        'every'
      )

      const linkedAssetsLiabilities = queryLinkedRecords(
        activeAssetsLiabilities,
        'documents',
        [rejectRecord.id],
        'every'
      )

      const linkedPendingAssetsLiabilities = queryLinkedRecords(
        pendingAssetsLiabilities,
        'documents',
        [rejectRecord.id],
        'every'
      )

      try {
        await rejectDocument(
          pendingDocuments,
          rejectRecord,
          userId,
          masterKey,
          values.reasonReject
        )

        linkedContacts?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'contacts',
            linkedContacts,
            masterKey
          ))

        linkedPendingContacts?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'pendingContacts',
            linkedPendingContacts,
            masterKey
          ))

        linkedAssetsLiabilities?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'assetsLiabilities',
            linkedAssetsLiabilities,
            masterKey
          ))

        linkedPendingAssetsLiabilities?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'pendingAssetsLiabilities',
            linkedPendingAssetsLiabilities,
            masterKey
          ))
        setRejectRecord({})
        setRejecting(false)
        setRejectModalVisible(false)
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message:
              'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments',
            userId: userId
          }
        })
        dispatch(fetchPendingDocuments(userId, masterKey))
        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved: false,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'document'
          })
        )
        message.success(t('SUCCESSFULLY_REJECTED_DOCUMENTS'))
      } catch (error) {
        setRejecting(false)
        setRejectRecord({})
        message.error(t('FAILED_TO_REJECT_DOCUMENTS'))
      }
    })
  }

  const handleMoveOrCopy = () => {
    if (
      docItem.type === 'folder' &&
      destinationFolder.indexOf(docItem.path) === 0
    ) {
      message.error(
        `${t('FAILED_TO')} ${action}: ${t('THE_DESTINATION_IS_A_SUBFOLDER')}`
      )
      return
    }

    // validate when the destination is subfolder of a selected folder
    if (selectedRows.length) {
      const invalidChoice = selectedRows.find(
        s => s.type === 'folder' && destinationFolder.indexOf(s.path) === 0
      )

      if (invalidChoice) {
        message.error(
          `${t('THE_DESTINATION_IS_A_SUBFOLDER')} <${invalidChoice.name}>.`
        )
        return
      }
    }

    setIsMovingOrCopying(true)

    switch (action) {
      case 'Copy':
        handleCopy()
        break
      case 'Move':
        handleMove()
        break
      case 'Bulk move':
        handleMoveItems()
        break
      case 'Bulk copy':
        handleCopy(true)
        break
      default:
        break
    }
    localStorage.setItem('NotReload', true)
    addS3Change({
      variables: {
        message: 'documents',
        userId: userId
      }
    })
  }

  const handleCopy = async (isBulkCopy = false) => {
    const items = isBulkCopy ? selectedRows : [docItem]
    const newDocsToCopy = newDocumentsToCopy(
      user,
      userId,
      items,
      activeFolders,
      activeFiles,
      destinationFolder,
      breadcrumb
    )

    const newFiles = newDocsToCopy.newFiles || []
    const newFolders = newDocsToCopy.newFolders || []

    if (
      newFiles?.length &&
      sum([...newFiles.map(n => n?.file[0]?.size), usedStorage, backupSize]) >
        allowedStorage
    ) {
      Modal.warn({
        title: t('LOW_STORAGE_SPACE'),
        content: t('LOW_STORAGE_SPACE_CONTENT')
      })
      setIsMovingOrCopying(false)
      return
    }

    try {
      await copyItems(userId, newFiles, newFolders, masterKey)

      message.success(
        `${t('SUCCESSFULLY_COPIED')} ${
          isBulkCopy ? t('ITEMS').toLowerCase() : t('ITEM').toLowerCase()
        }`
      )
      setIsMovingOrCopying(false)
      setDirectoryModalVisible(false)
      isBulkCopy && setSelectedRows([])
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'documents',
          userId: userId
        }
      })
    } catch (err) {
      message.error(
        `${t('FAILED_TO_COPY')} ${
          isBulkCopy ? t('ITEMS').toLowerCase() : t('ITEM').toLowerCase()
        }`
      )
      setIsMovingOrCopying(false)
      onError(err)
    }
  }

  const handleMove = async () => {
    // moving a file to the its current folder, so don't need to do anything
    if (docItem.type !== 'folder' && destinationFolder === docItem.path) {
      setIsMovingOrCopying(false)
      setDirectoryModalVisible(false)
      return
    }

    try {
      await moveItem(
        user,
        userId,
        docItem,
        activeDocuments,
        activeFolders,
        activeFiles,
        destinationFolder,
        masterKey,
        breadcrumb
      )

      message.success(t('SUCCESSFULLY_MOVED_ITEM'))
      setIsMovingOrCopying(false)
      setDirectoryModalVisible(false)
    } catch (err) {
      message.error(t('FAILED_TO_MOVE_ITEM'))
      setIsMovingOrCopying(false)
      onError(err)
    }
  }

  const handleMoveItems = async () => {
    try {
      await moveItems(
        user,
        userId,
        selectedRows,
        activeDocuments,
        activeFolders,
        activeFiles,
        destinationFolder,
        masterKey,
        breadcrumb
      )

      message.success(t('SUCCESSFULLY_MOVED_ITEMS'))
      setIsMovingOrCopying(false)
      setDirectoryModalVisible(false)
      setSelectedRows([])
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'documents',
          userId: userId
        }
      })
    } catch (err) {
      message.error(t('FAILED_TO_MOVE_ITEMS'))
      setIsMovingOrCopying(false)
      onError(err)
    }
  }

  const handleApprove = async item => {
    try {
      const subItems = pendingDocuments.filter(
        d =>
          d.path.indexOf(item.path) === 0 &&
          (item.fileId ? item.name === d.fileName : true)
      )

      const pendingFilesSize = subItems
        .filter(item => item.fileId)
        .reduce((sum, item) => {
          return sum + item?.file[0].size
        }, 0)

      if (!allowedStorage || usedStorage + pendingFilesSize > allowedStorage) {
        Modal.warn({
          title: t('LOW_STORAGE_SPACE'),
          content: t('LOW_STORAGE_SPACE_CONTENT')
        })
        return
      }

      await approveDocument(pendingDocuments, item, userId, masterKey)

      //Delete pending records
      const parentItems = pendingDocuments.filter(
        d => !d.fileName && item.path.indexOf(d.path) === 0
      )

      const deletedItems = uniq([...parentItems, ...subItems])

      await permanentlyDeleteDocuments(
        userId,
        pendingDocuments,
        deletedItems,
        masterKey,
        'pendingDocuments'
      )
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'documents, pendingDocuments',
          userId: userId
        }
      })
      await api.handleAddRecordRequest(
        JSON.stringify({
          isApproved: true,
          primaryUserId: userId,
          fullname: fullName,
          recordType: 'document'
        })
      )
      dispatch(fetchPendingDocuments(userId, masterKey))
      message.success(t('SUCCESSFULLY_APPROVED_DOCUMENTS'))
    } catch (error) {
      message.error(t('FAILED_TO_APPROVE_DOCUMENTS'))
    }
  }

  const handleEditContent = async item => {
    const resBody = await s3Get(
      userId,
      item.fileId,
      { sub: item.sub },
      { responseType: 'blob' }
    )
    decryptFile(resBody, masterKey, uint8Array => {
      const fileContent = String.fromCharCode(...uint8Array)

      setSelectedTextFile({
        id: item.id,
        fileId: item.fileId,
        sub: item.sub,
        fileName: item.name.substr(0, item.name.lastIndexOf('.')),
        fileExtension: item.name.substr(item.name.lastIndexOf('.')),
        fileContent,
        location: item.location
      })

      setEnableCreateFile(true)
    })
  }

  const actionsDropdown = item => (
    <Dropdown
      overlay={
        !(isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL &&
        item.status ? (
          <Menu>
            <Menu.Item
              className="dropdown-button"
              onClick={() => handleApprove(item)}
            >
              {t('APPROVE')}
            </Menu.Item>
            <Menu.Item
              className="dropdown-button"
              onClick={() => {
                rejectFormRef.props.form.resetFields()
                setRejectModalVisible(true)
                setRejectRecord(item)
              }}
            >
              {t('REJECT')}
            </Menu.Item>
          </Menu>
        ) : (
          <Menu>
            <Menu.Item
              className="dropdown-button"
              onClick={() => {
                if (item.type === 'folder') {
                  setDocItem(item)
                  setEditFormVisible(true)
                } else {
                  setVisible(true)
                  setDocItem(item)
                  setIsEdited(true)
                }
              }}
            >
              {t('Edit')}
            </Menu.Item>

            <Menu.Item
              className="dropdown-button"
              // disabled={hasPending(item)}
              onClick={e => {
                setAction('Copy')
                setDirectoryModalVisible(true)
                setDocItem(item)
              }}
            >
              {t('COPY')}
            </Menu.Item>
            <Menu.Item
              className="dropdown-button"
              // disabled={hasPending(item)}
              onClick={e => {
                setAction('Move')
                setDirectoryModalVisible(true)
                setDocItem(item)
              }}
            >
              {t('MOVE')}
            </Menu.Item>
            {item.isContentEditable && (
              <Menu.Item
                className="dropdown-button"
                // disabled={hasPending(item)}
                onClick={() => handleEditContent(item)}
              >
                {t('EDIT_CONTENT')}
              </Menu.Item>
            )}
            {!isReadonly && (
              <Menu.Item
                className="dropdown-button"
                onClick={() => handleDeleteItem(item)}
                disabled={deleteDisabled(item)}
              >
                {t('DELETE')}
                {deleteDisabled(item) && (
                  <Tooltip
                    title={t('CANT_DELETE_FOLDER_WITH_FILES_INSIDE')}
                    arrowPointAtCenter
                    placement="right"
                  >
                    <Icon type="question-circle" />
                  </Tooltip>
                )}
              </Menu.Item>
            )}

            {/* {isReadonly && (
              <Menu.Item
                className="dropdown-button"
                onClick={() => handleRemove(item)}
              >
                {t('Remove')}
              </Menu.Item>
            )} */}
            {!isReadonly && (
              <Menu.Item
                className="dropdown-button"
                onClick={() => {
                  viewDetail(item.id)
                  setShareDataModalVisible(true)
                  // setShowDetail(true)
                  setSelectedDoc(item)
                }}
              >
                {t('Share')}
              </Menu.Item>
            )}
          </Menu>
        )
      }
      placement="bottomRight"
      trigger={['click']}
    >
      <Icon type="ellipsis" />
    </Dropdown>
  )

  const deleteDisabled = item =>
    item.type === 'folder' &&
    (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
      ? [...activeFiles, ...pendingFiles]
      : activeFiles
    ).find(file => file?.path.indexOf(item.path) === 0)

  // const hasPending = item =>
  //   item.type === 'folder' &&
  //   (item.pendingFilesCount || item.pendingFoldersCount)

  const getFileExtension = filename => {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
  }

  const showLocationImage = (recordId, level) => {
    setLocationImageModalVisible(true)
    setLocationLevel(level)
    setLocationRecordId(recordId)
  }

  const columns = [
    {
      key: 'name',
      title: t('NAME'),
      render: (text, record) => (
        <span
          className="icon-name"
          onClick={e => {
            e.stopPropagation()
            handleDoubleClick(record)
          }}
        >
          {record.type === 'folder' ? (
            folderWithIcon(record.folderIcon, record.folderType, 30)
          ) : (
            <Icon
              type={record.type}
              style={{ fontSize: '30px' }}
              theme={isLinked(record.id) ? 'twoTone' : ''}
            />
          )}
          <span className="name" style={{ whiteSpace: 'nowrap' }}>
            {(!isModal ||
              isProfessionalDeputy ||
              (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL &&
            record.status ? (
              <i>{record.name}</i>
            ) : (
              record.name
            )}
          </span>
        </span>
      ),
      sorter: (a, b) =>
        a.type === b.type
          ? getFileExtension(a.name) === getFileExtension(b.name)
            ? a.name === b.name
              ? ''
              : a.name.localeCompare(b.name)
            : getFileExtension(a.name).localeCompare(getFileExtension(b.name))
          : b.type.localeCompare(a.type),
      defaultSortOrder: 'ascend'
    },
    {
      key: 'actions',
      render: (text, record) =>
        (!isReadonly || checkPermission(permissions, record.id)) &&
        actionsDropdown(record),
      align: 'right'
    },
    {
      key: 'starred',
      render: (text, record) => (
        <StarIcon
          item={record}
          handleStar={handleStar}
          isReadonly={isReadonly}
          t={t}
        />
      )
    },
    {
      key: 'description',
      title: 'Containing',
      render: (text, record) =>
        record.type === 'folder' && (
          <>
            {accessLevel === ACCESS_LEVEL.NEED_APPROVAL && record.status ? (
              <span>
                <i>
                  {record.pendingFoldersCount} {t('PENDING_FOLDES')}
                  {' & '} {record.pendingFilesCount} {t('PENDING_FILES')}
                </i>
              </span>
            ) : (
              <>
                <span>
                  {record.foldersCount} {t('FOLDERS').toLowerCase()} &{' '}
                  {record.filesCount} {t('FILES').toLowerCase()}
                </span>
                <br />
                {(!isModal ||
                  isProfessionalDeputy ||
                  (isDelegateByPD && isReadonly)) &&
                  (!!record.pendingFoldersCount ||
                    !!record.pendingFilesCount) && (
                    <span>
                      <i>
                        {record.pendingFoldersCount} {t('PENDING_FOLDES')}
                        {' & '} {record.pendingFilesCount} {t('PENDING_FILES')}
                      </i>
                    </span>
                  )}
              </>
            )}
          </>
        )
    },
    {
      key: 'status',
      render: (text, record) =>
        (!isModal || isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL &&
        (record.status ? <i>{t('PENDING')}</i> : '')
    },
    {
      key: 'size',
      title: 'Size',
      render: (text, record) => {
        return (
          <span>
            {record.type === 'folder' ? record.folderSize : record.size}
          </span>
        )
      }
    },
    // {
    //   key: 'picture',
    //   title: 'Picture',
    //   render: (text, record) => (
    //     <ShowImage
    //       record={locations.find(lc => lc._id === record.location) || {}}
    //     />
    //   )
    // },
    {
      key: 'location',
      title: 'Location',
      render: (text, record) => {
        const locationRecord = activeLocations.find(
          item => item._id === record.location
        )

        return locationRecord ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <Icon
              type="environment"
              style={{ marginRight: 5, color: '#0061D4' }}
            />
            <span
              className="clickable-text"
              onClick={() => showLocationImage(record.location, 1)}
            >
              {locationRecord.level1.length > 25
                ? locationRecord.level1.slice(0, 25).concat('...')
                : locationRecord.level1}
              {locationRecord.level2 ? ', ' : ''}
            </span>
            {locationRecord.level2 && (
              <span
                className="clickable-text"
                style={{ paddingLeft: 5 }}
                onClick={() => showLocationImage(record.location, 2)}
              >
                {locationRecord.level2.length > 25
                  ? locationRecord.level2.slice(0, 25).concat('...')
                  : locationRecord.level2}
                {locationRecord.level3 ? ', ' : ''}
              </span>
            )}
            {locationRecord.level3 && (
              <span
                className="clickable-text"
                style={{ paddingLeft: 5 }}
                onClick={() => showLocationImage(record.location, 3)}
              >
                {locationRecord.level3.length > 25
                  ? locationRecord.level3.slice(0, 25).concat('...')
                  : locationRecord.level3}
              </span>
            )}
          </div>
        ) : (
          ''
        )
      }
    }
  ]

  const selectedItemStyle = item => {
    const selectedItems =
      isModal && !isSelectedMutilpleFiles
        ? [{ id: selectedDoc }]
        : isSelectedMutilpleFiles
        ? selectedFilesFolders
        : selectedRows

    return `list-item ${
      selectedItems.find(sr => sr.id === item.id) ? 'selected-item' : ''
    }`
  }

  const bulkActionsMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setAction('Bulk move')
          setDirectoryModalVisible(true)
        }}
      >
        {t('MOVE')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleDeleteItems()}>
        {t('DELETE')}
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          setAction('Bulk copy')
          setDirectoryModalVisible(true)
        }}
      >
        {t('COPY')}
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="file-list">
      {isModal && isSelectedMutilpleFiles
        ? selectedFilesFolders.length > 1 && (
            <div style={{ marginBottom: 10 }}>
              <Span>{`${t('SELECTED_RECORDS')}: ${
                selectedFilesFolders.length
              }`}</Span>
              <Divider type="verticle" />
            </div>
          )
        : !isReadonly &&
          selectedRows.length > 1 && (
            <div style={{ marginBottom: 10 }}>
              <Span>{`${t('SELECTED_RECORDS')}: ${selectedRows.length}`}</Span>
              <Divider type="verticle" />
              <Dropdown overlay={bulkActionsMenu}>
                <Button type="primary">
                  {t('ACTIONS')} <Icon type="down" />
                </Button>
              </Dropdown>
            </div>
          )}

      {viewMode === 'grid' && (
        <List
          grid={
            linkedAssetLiabilityId ? filesGridLayoutInModal : filesGridLayout
          }
          dataSource={
            isViewSharedData
              ? content.filter(c => recordIds.includes(c.id))
              : content
          }
          renderItem={(item, index) => {
            return (
              <List.Item>
                <div
                  className={selectedItemStyle(item)}
                  onClick={e => handleClick(item, e, index)}
                  onDoubleClick={() => handleDoubleClick(item)}
                >
                  <div className="icon-actions">
                    {item.type === 'folder' ? (
                      folderWithIcon(item.folderIcon, item.folderType, 32)
                    ) : (
                      <Icon
                        type={item.type}
                        style={{
                          fontSize: '32px',
                          color: theme.dark1
                        }}
                        theme={
                          item.type === 'folder'
                            ? 'filled'
                            : isLinked(item.id)
                            ? 'twoTone'
                            : ''
                        }
                      />
                    )}
                    <div>
                      <StarIcon
                        item={item}
                        handleStar={handleStar}
                        isReadonly={isReadonly}
                        t={t}
                      />
                      {!isReadonly && (
                        <span style={{ marginLeft: 10 }}>
                          {actionsDropdown(item)}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="item-name"
                    onClick={() => handleDoubleClick(item)}
                  >
                    {item.status ? (
                      <i>
                        {item.name} ({t('PENDING')})
                      </i>
                    ) : (
                      item.name
                    )}
                  </div>
                  <div className="sub-title">
                    {item.type === 'folder' ? (
                      <>
                        {accessLevel === ACCESS_LEVEL.NEED_APPROVAL &&
                        item.status ? (
                          <span>
                            <i>
                              {item.pendingFoldersCount} {t('PENDING_FOLDES')}
                              {' & '} {item.pendingFilesCount}{' '}
                              {t('PENDING_FILES')}
                            </i>
                          </span>
                        ) : (
                          <>
                            <span>
                              {item.foldersCount} {t('FOLDERS').toLowerCase()}
                              {' &'}
                              {item.filesCount} {t('FILES').toLowerCase()}
                            </span>
                            <br />
                            {(!isModal ||
                              isProfessionalDeputy ||
                              (isDelegateByPD && isReadonly)) &&
                              (!!item.pendingFoldersCount ||
                                !!item.pendingFilesCount) && (
                                <span>
                                  <i>
                                    {item.pendingFoldersCount}{' '}
                                    {t('PENDING_FOLDES')}
                                    {' & '} {item.pendingFilesCount}{' '}
                                    {t('PENDING_FILES')}
                                  </i>
                                </span>
                              )}
                          </>
                        )}
                        <br />
                        <span>{item.folderSize}</span>
                      </>
                    ) : (
                      `${item.size}`
                    )}
                  </div>
                </div>
              </List.Item>
            )
          }}
        />
      )}
      {viewMode === 'list' && (
        <Table
          scroll={{ x: true }}
          className={
            isModal
              ? 'tour-select-docs-to-link'
              : 'tour-select-docs-to-link table-file-list'
          }
          // scroll={{ x: true }}
          rowSelection={{
            selectedRowKeys: selectedRows.map(row => row.id),
            onChange: (_selectedRowKeys, selectedRows) =>
              setSelectedRows(selectedRows)
          }}
          dataSource={
            isViewSharedData
              ? content.filter(c => recordIds.includes(c.id))
              : content
          }
          onChange={(pagination, filters, sorter, extra) => {
            setCurrentData(extra.currentDataSource)
          }}
          columns={columns}
          rowKey="id"
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              handleDoubleClick(record)
            },
            onClick: e => handleClick(record, e, rowIndex)
          })}
          rowClassName={record => selectedItemStyle(record)}
          // loading={isLoading}
          size={isMdUp ? '' : 'middle'}
        />
      )}

      <FileDetails
        visible={visible}
        setVisible={setVisible}
        docItem={docItem}
        isEdited={isEdited}
        setIsEdited={setIsEdited}
      />

      <CreateFolderModal
        wrappedComponentRef={editFolderFormRef}
        visible={editFormVisible}
        isEditFolder={editFormVisible}
        handleSaveFolder={() => {
          handleRenameFolder(docItem)
          // setIsChanged(false)
          editFolderForm.props.form.resetFields()
          editFolderForm.props.form.setFieldsValue({
            location: ''
          })
          setDocItem({})
        }}
        handleCancel={() => {
          setEditFormVisible(false)
          // setIsChanged(false)
          editFolderForm.props.form.resetFields()
          editFolderForm.props.form.setFieldsValue({
            location: ''
          })
        }}
        folderName={docItem.name}
        folderIcon={docItem.folderIcon}
        folderType={docItem.folderType}
        location={docItem.location}
        folders={activeFolders}
        breadcrumb={breadcrumb}
        isReadonly={isReadonly}
        activeLocations={activeLocations}
      />
      <Modal
        visible={directoryModalVisible}
        title={`${action} to`}
        onCancel={() => setDirectoryModalVisible(false)}
        onOk={handleMoveOrCopy}
        okButtonProps={{
          disabled: !destinationFolder,
          loading: isMovingOrCopying
        }}
      >
        <FileDirectory onFolderSelect={setDestinationFolder} />
      </Modal>
      <RejectModal
        wrappedComponentRef={fr => (rejectFormRef = fr)}
        visible={rejectModalVisible}
        handleOk={handleRejectDocument}
        handleCancel={() => setRejectModalVisible(false)}
        rejecting={rejecting}
      />
      <ShareDataModal
        visible={shareDataModalVisible}
        setVisible={setShareDataModalVisible}
        selectedDoc={selectedDoc}
        viewDetail={viewDetail}
        setSelectedDoc={setSelectedDoc}
      />
      <ShowLocationImageModal
        visible={locationImageModalVisible}
        setVisible={setLocationImageModalVisible}
        recordId={locationRecordId}
        setRecordId={setLocationRecordId}
        level={locationLevel}
        setLevel={setLocationLevel}
      />
    </div>
  )
}

export default withRouter(FileList)
